@tailwind base;
@tailwind components;
@tailwind utilities;

@import './animations.scss';
@import "~node_modules/yet-another-react-lightbox/dist/styles.css";

body {
  min-width: 1200px;
}

$barWidth: 8px;
// 自定义滚动条
::-webkit-scrollbar {
  width: $barWidth;
  height: $barWidth;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: $barWidth / 2;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
