.wrapper {
  @apply relative flex items-center;
}

.input {
  @apply truncate top-0 left-0 w-full border border-solid border-transparent outline-0;

  input {
    @apply truncate;
  }
}

.substitute {
  @apply inline-block overflow-hidden whitespace-nowrap h-[38px] opacity-0 border border-solid border-transparent;
}
