// -------------------
// keyframe animations
// -------------------
.slideInBottom {
  animation: slideInBottom 0.3s ease-in-out forwards;
}
.slideInLeft {
  animation: slideInLeft 0.3s ease-in-out forwards;
}
.slideInRight {
  animation: slideInRight 0.3s ease-in-out forwards;
}

.scaleIn {
  animation: scaleIn 0.3s ease-in-out forwards;
}

@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale3d(0, 0, 0);
    opacity: 0;
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

// --------------------
// transform animations
// --------------------
.transformSlideInBottomBase {
  @apply opacity-0 translate-y-1/3 transition-all;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-duration: 0.6s;
}
.transformSlideInBottom {
  @apply opacity-100 translate-y-0;
}

.transformSlideInBottomBaseForImg {
  @apply opacity-0 transition-all;
  transform: scale(0.95);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition-duration: 0.7s;
}

.transformSlideInBottomForImg {
  transform: scale(1);
  @apply opacity-100;
}
